import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { BreadcrumbItem, Container, Heading, HorizontalRule, Page, Picture, Section, TextLink } from '@troon/ui';
import { useLocation } from '@solidjs/router';
import { Title, Meta } from '@solidjs/meta';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { PictureSection } from '../../components/layouts/picture-section';
import type { RouteDefinition } from '@solidjs/router';

export default function GolfzonPage() {
	const loc = useLocation();
	return (
		<>
			<Title>Golfzon Social | Troon Privé Member Programs | Troon</Title>
			<Meta
				name="description"
				content="Troon Members, elevate your golf game and social experience with exclusive savings and benefits at Golfzon Social."
			/>

			<NoHydration>
				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/web/hero/golfzon.jpeg`}
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>Golfzon Social</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1">Golfzon Social</Heading>
					<p class="text-lg">
						Troon Members, elevate your golf game and social experience with exclusive savings and benefits at Golfzon
						Social.
					</p>
				</Hero>

				<Container>
					<Page>
						<PictureSection src={`${getConfigValue('IMAGE_HOST')}/web/hero/golfzon-feature.jpeg`}>
							<Heading as="h2">
								Unlock Premier Golf Experiences with Special Troon Private Club Member Benefits at Golfzon Social
							</Heading>
							<p>
								Troon Members, elevate your golf game and social experience with exclusive savings and benefits at
								Golfzon Social! As a valued Troon member, you now have access to special savings on bay time offering
								you a premier golfing experience anytime, rain or shine.
							</p>

							<p>Why Choose Golfzon Social?</p>

							<ul class="flex list-disc flex-col gap-2 ps-4">
								<li>
									<strong>World-Class Simulators:</strong> Play on over 240+ world-renowned courses with our
									top-of-the-line golf simulators.
								</li>

								<li>
									<strong>Year-Round Play:</strong> Enjoy perfect conditions every time, regardless of the weather
									outside.
								</li>
								<li>
									<strong>Fun for All:</strong> Perfect for solo practice, friendly games, or social events with fellow
									golf enthusiasts.
								</li>

								<li>
									<strong>Troon Member Benefits:</strong> Exclusive savings on bay time (details below).s can only now
									be booked via the link provided by your home club.
								</li>
							</ul>
						</PictureSection>

						<HorizontalRule />

						<Section>
							<Heading as="h2">Golfzon Social Locations</Heading>

							<ul class="flex flex-col gap-4">
								<For each={locations}>
									{(location) => (
										<li class="flex items-center gap-4 rounded border border-neutral bg-neutral-100 p-4">
											<Picture
												src={location.image}
												alt=""
												width={200}
												height={200}
												sizes="9rem"
												class="aspect-square shrink grow-0 rounded"
											/>
											<div class="flex flex-col justify-center gap-1">
												<p class="text-lg font-semibold">{location.title}</p>
												<p class="text-neutral-800">
													Phone: <TextLink href={`tel:${location.phone}`}>{location.phone}</TextLink>
												</p>
											</div>
										</li>
									)}
								</For>
							</ul>
						</Section>
					</Page>
				</Container>
			</NoHydration>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const locations: Array<{ title: string; phone: string; image: string }> = [
	{
		title: 'Golfzon Social, Brooklyn',
		phone: '347.434.9593',
		image: `${getConfigValue('IMAGE_HOST')}/web/hero/golfzon-family.jpg`,
	},
	{
		title: 'Golfzon Social, Scarsdale',
		phone: '914.348.2250',
		image: `${getConfigValue('IMAGE_HOST')}/web/hero/golfzon-scarsdale.jpg`,
	},
	{
		title: 'Golfzon Social, West Nyack',
		phone: '845.579.8530',
		image: `${getConfigValue('IMAGE_HOST')}/web/hero/golfzon-zstrict-simulator.jpg`,
	},
];
